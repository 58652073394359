/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import NumberDistribution from "../../../images/Number Theory/Intro.webp";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ol: "ol",
    li: "li",
    h3: "h3",
    ul: "ul",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "introduction-to-number-theory",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction-to-number-theory",
    "aria-label": "introduction to number theory permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction to Number Theory"), "\n", React.createElement(_components.p, null, "In Competitive Programming"), "\n", React.createElement(_components.h2, {
    id: "what-is-number-theory",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-number-theory",
    "aria-label": "what is number theory permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is Number Theory"), "\n", React.createElement(_components.p, null, "Number Theory is the study and its application to solve problems related to integers and integral values functions.\nNumber Theory in Competitive Programming includes, but is not limited to, study and application of"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Operations like Multiplication, Exponents and Remainders of numbers"), "\n", React.createElement(_components.li, null, "Factors of Number ( GCD, LCM and Prime numbers )"), "\n", React.createElement(_components.li, null, "Factorial of Number"), "\n", React.createElement(_components.li, null, "Permutations and Combinations"), "\n", React.createElement(_components.li, null, "Sequences of integers, like Fibonacci Series, Prime numbers, square numbers etc."), "\n"), "\n", React.createElement(_components.h2, {
    id: "types-of-integers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#types-of-integers",
    "aria-label": "types of integers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Types of Integers"), "\n", React.createElement(_components.h3, {
    id: "1-on-the-basis-of-range-of-values",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-on-the-basis-of-range-of-values",
    "aria-label": "1 on the basis of range of values permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "1. On The Basis of range of Values"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Positive Integers : These are natural numbers. They include all the numbers from 1 to infinity. So, Positive Integers are 1, 2, 3, 4, ..."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Non-Negative Integers: These are whole numbers. They include all the positive integers ", React.createElement(_components.strong, null, "and 0"), ". So, Non Negative numbers are 0, 1, 2, 3, 4, ..."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Integers : These include all the positive and negative numbers. So, Integers include ... -4, -3, -2, -1, 0, 1, 2, 3, 4, ..."), "\n"), "\n"), "\n", "\n", React.createElement("div", {
    style: {
      textAlign: 'center'
    }
  }, React.createElement("img", {
    src: NumberDistribution,
    width: "50%",
    alt: "Number Distribution"
  })), "\n", React.createElement(_components.h3, {
    id: "2-on-the-basis-of-series",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-on-the-basis-of-series",
    "aria-label": "2 on the basis of series permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2. On the Basis of Series"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Perfect Squares : These are the numbers that are squares of other integers. All perfect squares must be whole numbers. These numbers are 0, 1, 4, 9, 16, ..."), "\n", React.createElement(_components.li, null, "Fibonacci Numbers : These are the numbers from fibonacci series. In this, next numbers are sum of previous 2 numbers. All fibonacci numbers are natural numbers.\nThese numbers are 1, 1, 2, 3, 5, 8, 13, ..."), "\n", React.createElement(_components.li, null, "Perfect Cubes : These are the numbers that are cubes of other integers. These can be positive negative or zero. For example  ... -27, -8, -1, 0, 1, 8, 27, ..."), "\n"), "\n", "\n", React.createElement(SEO, {
    title: "Introduction to Number Theory - Rust Programming",
    description: "Introduction To Number Theory in competitive programming in rust. Number Theory is study of integers and integral values"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
